import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import LinkI from '@material-ui/icons/LinkedIn';
import EmailI from '@material-ui/icons/Email';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import gscholar from '../assets/kisspng-google-scholar-education-research-doctor-of-philos-scholar-s-5aeaddc3af2de5.4479525015253416357175.png';
import MyLogo from './mylogo.png';
import MePic from './m copy.png';
import MePic2 from './OFFICIAL.png';
import MyCV from './MEHRNAZ_SABET_CV.pdf';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Mehrnaz Sabet
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
    Stack: {
        maxWidth: '33px'
    },
    ResearchG: {
        maxWidth: '39px'
    },
    appBar: {
        border: 'none',
        background: 'white',
        color: 'black'
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
        fontFamily: 'Open Sans, sans-serif',
        color: 'black',
        padding: "10px 15px",
        cursor: "pointer",
        textTransform: "unset",
        textDecoration: 'none',
        '&:hover': {
            borderBottom: '1px solid black',
            textDecoration: 'none'
        }
    },
    linkMobile: {
        margin: theme.spacing(1, 1.5),
        fontFamily: 'Open Sans, sans-serif',
        color: 'black',
        padding: "10px 15px",
        cursor: "pointer",
        width: "100%",
        textTransform: "unset",
        textDecoration: 'none',
        '&:hover': {
            borderBottom: '1px solid black',
            textDecoration: 'none'
        }
    },
    textMain: {
        fontFamily: 'Open Sans, sans-serif',
        color: 'black',
        marginBottom: '15px'
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        position: 'relative',
        height: '100vh',
        background: `url("${MePic}")`,
        backgroundSize: '75% 70%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 90%'
    },
    heroContent2: {
        padding: theme.spacing(0, 0, 6),
        position: 'relative'
    },
    cardHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    carImg: {
        maxWidth: '100%'
    },
    carousel: {
        height: '100vh',
        maxWidth: '100%'
    },
    mainTitle: {
        top: '45%',
        position: 'absolute',
        left: '12%',
        transform: 'translate(-15%,-50%)',
        fontFamily: 'Roboto Slab, sans-serif',
        color: 'black' //01CB5C
    },
    text: {
        fontFamily: 'Roboto Slab, sans-serif',
        color: 'black', //01CB5C,
        textAlign: 'left'
    },
    moreInfo: {
        fontSize: '1.5rem',
        display: 'block',
        color: '#6D267A',
        marginTop: '15px',
    },
    hands: {
        maxWidth: '300px',
        position: 'absolute',
        bottom: '0'
    },
    feature: {
        height: '550px',
        background: '#93d4f1',
        padding: '25px 30px',
        boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    featureMe: {
        height: '550px',
        background: '#93d4f1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
        borderRadius: '8px',
    },
    review: {
        background: 'blue'
    },
    logo: {
        maxWidth: '40px'
    },
    myPic: {
        maxWidth: '700px',
        position: 'absolute',
        bottom: '0',
        right: '0'
    },
    EmailB: {
    '&hover': {
            backgroundColor: 'none !important'
        }
    },
    mainPros: {
        paddingTop: '190px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '15vh',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    about: {
        maxWidth: '80%',display:'block',margin:'0 auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
        },
    },
    Buttmob: {
        [theme.breakpoints.down('md')]: {
            display:'flex',marginTop:'5%',alignItems:'center',flexDirection:'column',justifyContent:'center',paddingTop:'15px'
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

}));



export default function Pricing() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/*<MenuItem>*/}
            {/*    <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href="/#publications" className={classes.linkMobile}>*/}
            {/*      Publications*/}
            {/*    </Link>*/}
            {/*</MenuItem>*/}
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href="/#academic" className={classes.linkMobile}>
                   Experience
                </Link>
            </MenuItem>
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href="/#awards" className={classes.linkMobile}>
                    Awards
                </Link>
            </MenuItem>
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href="/#publications" className={classes.linkMobile}>
                    Publications&Projects
                </Link>            </MenuItem>


                <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href="/#rjourney" className={classes.linkMobile}>
                    Research Journey
                </Link>            </MenuItem>
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href="/#pact" className={classes.linkMobile}>
                    Professional Activities
                </Link>            </MenuItem>

            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href={`${MyCV}`} className={classes.linkMobile}>
                    CV
                </Link>
            </MenuItem>
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" href="/about" className={classes.linkMobile}>
                    About
                </Link>
            </MenuItem>
        </Menu>
    );
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar  color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton  href={"/"} edge="start" color="inherit" aria-label="menu" style={{marginRight: 'auto', display: 'flex'}}>
                        <img src={MyLogo} className={classes.logo} />
                    </IconButton>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            edge="end"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                    <nav edge="end" className={classes.sectionDesktop}>
                        {/*<a variant="button" color="textPrimary"  href={"/#publications"} className={classes.link}>*/}
                        {/*    Publications*/}
                        {/*</a>*/}
                        <Link variant="button" color="textPrimary" href={"/#academic"}  className={classes.link} >
                             Experiences
                        </Link>
                        <Link variant="button" color="textPrimary" href={"/#awards"} className={classes.link} activeClass={classes.active}>
                            Awards
                        </Link>
                        {/*<Link variant="button" color="textPrimary" href={"/#teach"}   className={classes.link} activeClass={classes.active}>*/}
                        {/*    Teaching Experiences*/}
                        {/*</Link>*/}
                        <Link variant="button" color="textPrimary" href={"/#exp"}  className={classes.link} activeClass={classes.active}>
                            Publications&Projects
                        </Link>
                        <Link variant="button" color="textPrimary" href={"/#rjourney"}  className={classes.link} activeClass={classes.active}>
                            Research Journey
                        </Link>
                        <Link variant="button" color="textPrimary" href={"/#pact"}  className={classes.link} activeClass={classes.active}>
                            Professional Activities
                        </Link>
                        {/*<Link variant="button" color="textPrimary" href={"/#pro"} className={classes.link} activeClass={classes.active}>*/}
                        {/*    Projects*/}
                        {/*</Link>*/}
                        {/*<Link variant="button" color="textPrimary" href="/artworks" className={classes.link}>*/}
                        {/*    Artworks*/}
                        {/*</Link>*/}
                        <Link variant="button" color="textPrimary" href={`${MyCV}`} target="_blank" type="application/pdf; download='Mehrnaz_Sabet_CV'" className={classes.link}>
                            CV
                        </Link>
                        <Link variant="button" color="textPrimary" href="/about" className={classes.link}>
                            About
                        </Link>
                    </nav>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}


            <Container id="research" maxWidth="lg" component="main" className={classes.mainPros}>
                <Typography  variant="h3" color="textPrimary" className={classes.textMain}>
                    Nice to meet you!
                </Typography>
                <Grid container spacing={3} alignItems="flex-end">
                    <Grid style={{position: 'relative'}} item xs={12} md={6}>
                        <div className={classes.feature} style={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                            <img src={MePic2} className={`${classes.about} ${classes.sectionMobile}`} style={{maxWidth:"250px", marginBottom:"25px"}}/>
                            <Typography variant="body1" color="textPrimary" style={{color:"black", fontFamily: 'Roboto Slab, sans-serif', fontWeight:500}}>
                                I'm Mehrnaz Sabet. I'm a PhD candidate at the college of Computing and Information Science at <a target="_blank" href="https://cornell.edu" style={{color: 'black', cursor:'pointer'}}>Cornell University</a>.
                                <br/><br/> I work in the Collaborative Technologies Lab where I lead research on human-drone systems.  I'm lucky to work with Sanjiban Choudhury, Susan Fussell, Malte Jung, and Qian Yang. I received my BSc. in Computer Engineering.
                                <br/><br/>
                                In my research, I explore novel ways in multi-agent learning for enabling collaborative autonomy.
                                My goal is to contribute to a future that brings us and machines together and empowers us to collaboratively solve complex problems.
                            </Typography>
                            <div  className={`${classes.sectionMobile} ${classes.Buttmob}`}>
                            <Button style={{textTransform: 'none'}} href="https://mail.google.com/mail/?view=cm&fs=1&to=ms3662@cornell.edu" target="_blank" aria-label="delete" className={`${classes.margin} ${classes.EmailB} ${classes.sectionMobile}`}>
                                <EmailI fontSize="medium" style={{marginRight: '10px'}} />
                                <Typography variant="body1" color="textPrimary">
                                    ms3662@cornell.edu
                                </Typography>
                            </Button>
                            <Grid style={{position: 'relative'}} item xs={12} className={classes.sectionMobile}>

                                <Button aria-label="delete" className={classes.margin} href="https://scholar.google.com/citations?user=VmhpRV8AAAAJ&hl=en" target="_blank">
                                    {/*<LinkI fontSize="large" />*/}
                                    <img src={gscholar} style={{maxWidth:"30px"}}/>
                                </Button>
                                <Button aria-label="delete" className={classes.margin} href="https://www.linkedin.com/in/mehrnaz-sabet-098074139" target="_blank">
                                    <LinkI fontSize="large" />
                                </Button>

                            </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid style={{position: 'relative'}} item xs={12} sm={6} className={classes.sectionDesktop}>
                        <div >
                            <img src={MePic2} className={classes.about}/>
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',paddingTop:'15px'}}>

                                <div style={{height:"1px", width: "100%", background:"black",marginBottom:"10px"}}></div>
                                <Grid style={{position: 'relative'}} item xs={12}>
                                    <Button aria-label="delete" className={classes.margin} href="https://scholar.google.com/citations?user=VmhpRV8AAAAJ&hl=en" target="_blank">
                                        {/*<LinkI fontSize="large" />*/}
                                        <img src={gscholar} style={{maxWidth:"30px"}}/>
                                    </Button>
                                    <Button aria-label="delete" className={classes.margin} href="https://www.linkedin.com/in/mehrnaz-sabet-098074139" target="_blank">
                                        <LinkI fontSize="large" />
                                    </Button>
                                    <Button style={{textTransform: 'none'}} href="https://mail.google.com/mail/?view=cm&fs=1&to=ms3662@cornell.edu" target="_blank" aria-label="delete" className={`${classes.margin} ${classes.EmailB}`}>
                                        <EmailI fontSize="large" style={{marginRight: '10px'}}/>
                                        <Typography variant="body1" color="textPrimary">
                                            ms3662@cornell.edu
                                        </Typography>
                                    </Button>

                                </Grid>
                            </div>

                        </div>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="lg" component="footer" className={classes.footer}>

                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
            {/* End footer */}
        </React.Fragment>
    );
}
