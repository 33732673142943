import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'normalize.css/normalize.css';
import './App.css';
import Home from './components/Main';
import About from './components/About';
import history from './history';
import Resume from './components/Resume.js';
// import useGaTracker from './useGaTracker'
import ReactGA from 'react-ga';
import GA from './useGaTracker'
//    ReactGA.pageview('/homepage');

// ReactGA.initialize('UA-158078611-1');

function initializeReactGA() {
    // history.listen(location => ReactGA.pageview(location.pathname));
    // console.log("history")
    // history.listen(location => {
    //     console.log(location)
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
    // });
}

function App() {
    GA.init() && initializeReactGA()
    return (
        <BrowserRouter history={history}>
            {/*{ GA.init() && <GA.RouteTracker /> }*/}
              <Routes>
                  {/*<Switch>*/}
                  {/*    <Route element={<GA.GoogleAnalytics/>} />*/}
                      <Route path="/about" element={<About />} />
                      <Route path="/resume" element={<Resume />} />
                      <Route path="/" element={<Home />} />
                      <Route path="/home" element={<Home />} />
                    <Route path="*" element={<Home />} />
                  {/*</Switch>*/}
              </Routes>
        </BrowserRouter>
  );
}

export default App;
