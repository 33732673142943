import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyLogo from './mylogo.png';
import MePic from './m copy.png';
import MePic2 from './m.png';
import droneSAR from './drneSAR.jpeg';
import BT from './bt.jpeg';
import OBSRV from './observe.jpeg';
import Roof from './roof.jpeg';
import Innov from './innov.jpg';
import dronecollabData from '../assets/lottie/data.json'
import VRdata from '../assets/lottie/dataVR.json'
import tpsi from '../assets/lottie/tapsi.json'
import realData from '../assets/lottie/71623-harpoon.json'
import Secdata from '../assets/lottie/ceta.json'
import landData from '../assets/lottie/69182-landing-page.json'
import smdata from '../assets/lottie/smdata.json'
import droner from '../assets/lottie/86094-drone-fork.json'
import cetar from '../assets/lottie/cetar.json'
import mod from '../assets/lottie/mod.json'
import chib from '../assets/lottie/74907-search-white-tshirt.json'
import MyCV from './MEHRNAZ_SABET_CV.pdf';
import { useSpring, animated } from 'react-spring/web.cjs';
import LinkI from "@material-ui/icons/LinkedIn";
import {Email} from "@material-ui/icons"; // web.cjs is required for IE 11 support

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://its-mehrnaz.me/">
                Mehrnaz Sabet
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
    appBar: {
        border: 'none',
        background: 'white',
        color: 'black'
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
        fontFamily: 'Open Sans, sans-serif',
        color: 'black',
        padding: "10px 15px",
        borderBottom: '0px solid black',
        cursor: "pointer",
        textDecoration: 'none',
        transition: 'border 0.1s ease-out',
        '&:hover': {
            borderBottom: '1px solid black',
            transition: "all 100ms linear",
            textDecoration: 'none'
        }
    },
    linkMobile: {
        margin: theme.spacing(1, 1.5),
        fontFamily: 'Open Sans, sans-serif',
        color: 'black',
        width: "100%",
        padding: "10px 15px",
        borderBottom: '0px solid black',
        cursor: "pointer",
        textDecoration: 'none',
        transition: 'border 0.1s ease-out',
        '&:hover': {
            borderBottom: '1px solid black',
            transition: "all 100ms linear",
            textDecoration: 'none'
        }
    },
    textMain: {
        // fontFamily: 'Open Sans, sans-serif',
        fontFamily: 'Roboto Slab, sans-serif',
        color: 'black',
        marginBottom: '15px'
    },
    active: {
        fontWeight: 700,
        fontSize: 14
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        position: 'relative',
        height: '700px',
        background: `url("${MePic}")`,
        backgroundSize: '1000px auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 90%',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: '750px auto',
        },
        [theme.breakpoints.down('xs')]: {
            height: '750px',
            background: `url("${MePic2}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '80% 110vw',
            backgroundSize: '300px auto',
        },
        ['@media (min-width:1921px)']: {
            maxWidth: '100%',
        },
        ['@media (min-width:500px) and (max-width:600px)']: {
            backgroundPosition: '80% 72vw',
        },
    },
    heroContent2: {
        padding: theme.spacing(0, 0, 6),
        position: 'relative'
    },
    cardHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    carImg: {
        maxWidth: '100%'
    },
    mainTitle: {
        top: '50%',
        position: 'absolute',
        left: '15%',
        transform: 'translate(-15%,-50%)',
        fontFamily: 'Roboto Slab, sans-serif',
        color: 'black',
        ['@media (max-width: 1440px)']: {
            left: '9%',
            top: '45%'
        },
        [theme.breakpoints.down('md')]: {
            left: '18%',
            top: '33%'
        },
    },
    text: {
        fontFamily: 'Roboto Slab, sans-serif',
        color: 'black', //01CB5C,
        textAlign: 'left'
    },
    textAA: {
        fontFamily: 'Open Sans, sans-serif',
        color: 'black', //01CB5C,
        textAlign: 'left'
    },
    maintext: {
        fontFamily: 'Roboto Slab, sans-serif',
        color: 'black', //01CB5C,
        textAlign: 'left',
        marginTop: '-70px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '3rem',
        },
    },
    maintextinterest: {
        fontFamily: 'Roboto Slab, sans-serif',
        color: 'black', //01CB5C,
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
    moreInfo: {
        fontSize: '1.5rem',
        display: 'block',
        color: '#6D267A',
        marginTop: '15px',
    },
    hands: {
        maxWidth: '300px',
        position: 'absolute',
        bottom: '0'
    },
    feature: {
        height: '400px',
        background: '#93d4f1',
        padding: '25px 25px',
        boxShadow: '4px 12px 40px 6px rgba(0,0,0,0.09)',
        borderRadius: '8px',
        marginBottom: '120px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
        // [theme.breakpoints.only('sm')]: {
        //     height: '450px',
        // },
    },
    featurez: {
        height: '400px',
        background: '#93d4f1',
        padding: '25px 25px',
        // boxShadow: '4px 12px 40px 6px rgba(0,0,0,0.09)',
        borderRadius: '8px',
        marginBottom: '120px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
        // [theme.breakpoints.only('sm')]: {
        //     height: '450px',
        // },
    },
    featurex: {
        height: '300px',
        background: '#93d4f1',
        padding: '25px 25px',
        boxShadow: '4px 12px 40px 6px rgba(0,0,0,0.09)',
        borderRadius: '8px',
        marginBottom: '80px',
        position: 'relative',
        overflow: "hidden",
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
        // [theme.breakpoints.only('sm')]: {
        //     height: '450px',
        // },
    },
    feature100: {
        height: '100%',
        background: '#93d4f1',
        padding: '25px 25px',
        boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
        borderRadius: '8px',
        marginBottom: '120px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
        [theme.breakpoints.only('sm')]: {
            height: '450px',
        },
    },
    feature1000: {
        height: '100%',
        background: '#93d4f1',
        padding: '25px 25px',
        boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
        borderRadius: '8px',
        marginBottom: '50px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
        [theme.breakpoints.only('sm')]: {
            height: '450px',
        },
    },
    review: {
        background: 'blue'
    },
    logo: {
        maxWidth: '40px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    myPic: {
        maxWidth: '700px',
        position: 'absolute',
        bottom: '0',
        right: '0',
    },
    mainPros: {
        paddingTop: '50px',
        marginTop: '50px',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0px',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    tapc: {
        maxWidth: '35%',
        position: 'absolute',
        bottom: '10px',
        zIndex: 1,
        right: 0,
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            maxWidth: '100%',
            zIndex: '0',
            marginTop: '10%',
            display: 'block',
            margin: '10% auto',
        }
    },
    chib: {
        maxWidth: '35%',
        position: 'absolute',
        bottom: '10px',
        right: 0,
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            maxWidth: '100%',
            zIndex: '0',
            marginTop: '10%',
            display: 'block',
            margin: '10% auto',
        }
    },
    Lamaso: {
        maxWidth: '45%',
        position: 'absolute',
        bottom: '0px',
        borderRadius: '8px',
        right: 0,
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            maxWidth: '100%',
            zIndex: '0',
            marginTop: '10%',
            display: 'block',
            margin: '10% -25px -25px auto',
        }
    },
    SM: {
        maxWidth: '35%',
        position: 'absolute',
        bottom: '0px',
        borderRadius: '8px',
        right: 0,
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            maxWidth: '100%',
            zIndex: '0',
            marginTop: '10%',
            display: 'block',
            margin: '10% auto',
        }
    },
    Research: {
        maxWidth: '45%',
        top: '0',
        right: '0',
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            maxWidth: '100%',
            zIndex: '0',
            display: 'block',
            margin: '0% auto',
        }
    },
    Research1: {
        maxWidth: '55%',
        bottom: '10px',
        right: '0',
        position: 'absolute',
    },
    Research2: {
        maxWidth: '55%',
        bottom: '10px',
        right: '0',
        position: 'absolute',
    },
    Teaching: {
        maxWidth: '35%',
        bottom: '15px',
        right: '0',
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            maxWidth: '100%',
            zIndex: '0',
            marginTop: '10%',
            display: 'block',
            margin: '10% auto',
        }
    },
    MS: {
        maxHeight: '80%',
        top: '50%',
        transform: "translateY(-50%)",
        right: '10px',
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            maxWidth: '100%',
            transform: "translateY(0%)",
            zIndex: '0',
            display: 'block',
            margin: '10% auto',
        }
    },
    MyClass: {
        maxWidth: '40vw',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '80vw',
        },
    },
    ButtonsMore: {
        display: 'flex',
        zIndex: '555',
        // justifyContent: 'space-between',
        paddingTop: '3%',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            maxWidth: '100%',
            paddingTop: '7%',
        },
    },
    ButtonsMore2: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '355px',
        paddingTop: '4%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            maxWidth: '100%',
            paddingTop: '7%',
        },
    },
    MarginButton: {
        marginRight: '2%',
        zIndex: 2,
        // maxWidth: '455px',
        maxWidth: "40%",
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
            width: "100%",
                marginBottom: '2%',
            maxWidth: "100%"
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginBottom: '2%'
        // },
    },
    MarginButtonA: {
        color: 'white',
        padding: '10px 15px',
        position: 'absolute',
        width: 'auto',
        left: '20px',
        bottom: '30px'
    },
    MarginButtonBott: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '20px',
            display: 'block',
            margin: 'auto',
            maxWidth: '100%',

        },
    },
    flexCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    Aw1: {
        maxWidth: '350px',
        display: 'block',
        margin: '0 auto',
        borderRadius: '8px',
        marginTop: '5px'
    },
    MarginButtonBottt: {
        // maxWidth: "40%",
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            maxWidth: '100%',
            margin: 'auto'
        },
    },
    DEsc: {
        border: '1px solid black',
        maxWidth: '100%',
        borderRadius: '8px',
        padding: '20px',
        // height: '35%',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            margin: 'auto',
            maxWidth: '100%'
        },
    },
    study: {
        padding:"30px 15%",
        [theme.breakpoints.up('xl')]: {
            padding:"30px 2%",

        },
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
    },
    reactPlayer: {
        position: 'absolute',
        top: '0',
        left: '0'
    }
}));
const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};


export default function Pricing() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const menuId = 'primary-search-account-menu';
    const defaultOptions = (name) => {
        return  {
            loop: true,
            autoplay: true,
            animationData: name === "dronecollab" ? dronecollabData : name === "VR" ? VRdata : name === "Secdata" ? Secdata : name === "Landing" ? landData : name==="realState" ? realData : name === "tapsi" ? tpsi : name === "chib" ? chib : name === "mod" ? mod : name === "sm" ? smdata : name === "droner" ? droner : name === "cetar" ? cetar : "" ,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
    }
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" to="academic" spy={true} smooth={true} duration={1000} className={classes.linkMobile} activeClass={classes.active}>
                    Experience
                </Link>
            </MenuItem>
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" to="awards" spy={true} smooth={true} duration={1000} className={classes.linkMobile} activeClass={classes.active}>
                    Awards
                </Link>
            </MenuItem>

            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" to="publications" spy={true} smooth={true} duration={1000} className={classes.linkMobile} activeClass={classes.active}>
                    Publications&Projects
                </Link>            </MenuItem>
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" to="pact" spy={true} smooth={true} duration={1000} className={classes.linkMobile} activeClass={classes.active}>
                    Professional Activities
                </Link>            </MenuItem>
            <MenuItem>
                <Link onClick={handleMobileMenuClose} variant="button" color="textPrimary" to="rjourney" spy={true} smooth={true} duration={1000} className={classes.linkMobile} activeClass={classes.active}>
                    Research Journey
                </Link>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose}>
                <a  variant="button" color="textPrimary" href={`${MyCV}`} target="_blank" type="application/pdf; download='Mehrnaz_Sabet_CV'" className={classes.linkMobile}>
                    CV
                </a>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <a  variant="button" color="textPrimary" href="/about" className={classes.linkMobile}>
                    About
                </a>
            </MenuItem>
        </Menu>
    );
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar  color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton href={"/"} edge="start" color="inherit" aria-label="menu" style={{marginRight: 'auto', display: 'flex'}}>
                        <img src={MyLogo} className={classes.logo} />
                    </IconButton>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            edge="end"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                    <nav edge="end" className={classes.sectionDesktop}>
                        {/*<Link variant="button" color="textPrimary" to="publications" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>*/}
                        {/*  Publications*/}
                        {/*</Link>*/}
                        <Link variant="button" color="textPrimary" to="academic" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>
                          Experience
                        </Link>
                        <Link variant="button" color="textPrimary" to="awards" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>
                            Awards
                        </Link>

                        {/*<Link variant="button" color="textPrimary" to="teach" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>*/}
                        {/*    Teaching Experiences*/}
                        {/*</Link>*/}
                        <Link variant="button" color="textPrimary" to="publications" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>
                            Publications&Projects
                        </Link>
                        <Link variant="button" color="textPrimary" to="rjourney" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>
                            Research Journey
                        </Link>
                        <Link variant="button" color="textPrimary" to="pact" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>
                           Professional activities
                        </Link>
                        {/*<Link variant="button" color="textPrimary" to="pro" spy={true} smooth={true} duration={1000} className={classes.link} activeClass={classes.active}>*/}
                        {/*    Projects*/}
                        {/*</Link>*/}
                        {/*<Link variant="button" color="textPrimary" href="/artworks" className={classes.link}>*/}
                        {/*    Artworks*/}
                        {/*</Link>*/}
                        <a variant="button" color="textPrimary" href={`${MyCV}`} target="_blank" type="application/pdf; download='Mehrnaz_Sabet_CV'" className={classes.link}>
                            CV
                        </a>
                        <a variant="button" color="textPrimary" href="/about" className={classes.link}>
                            About
                        </a>
                    </nav>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}

            {/* Hero unit */}

            <Container maxWidth="xl" component="main" className={classes.heroContent}>
                {/*<img src={MePic} className={classes.myPic} />*/}
               <div className={classes.mainTitle} >
               <Typography className={classes.maintext} component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Hi, I'm Mehrnaz.
                </Typography>
                {/*<Typography className={classes.text} component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>*/}
                {/*    My goal is to solve problems, <br/> to empower people, <br/> and to create delightful experiences <br/> using new technologies.</Typography>*/}
                   <Typography className={classes.text} component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
                       PhD candidate @ Cornell University  <br/>
                       Working on Collaborative Autonomy</Typography>
                   <span style={{fontWeight:"700",fontSize:"16px"}}> Robotics </span> <span style={{fontWeight:"700",fontSize:"16px"}}><br/>Machine Learning</span> <br/><span style={{fontWeight:"700",fontSize:"16px"}}> Human-Computer Interaction </span>
                   <br/>
                   <div style={{marginTop:"10px"}}>
                       <span> Let's Connect </span>
                       <Button aria-label="delete" href="https://www.linkedin.com/in/mehrnaz-sabet-098074139" target="_blank">
                           <LinkI fontSize="large" />
                       </Button>
                       <Button aria-label="delete" href="https://mail.google.com/mail/?view=cm&fs=1&to=ms3662@cornell.edu" target="_blank">
                           <Email fontSize="large" />
                       </Button>
                   </div>
               </div>

            </Container>
            <Container maxWidth="lg" component="main" className={classes.study}>
                {/*<img src={MePic} className={classes.myPic} />*/}
               <Typography className={classes.maintextinterest} component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
                   My research aims <span style={{fontWeight:"700"}}> to enable machines to learn how to coordinate with humans as a team towards shared goals. </span> I believe learning effective cooperative behaviors can ultimately enable reliable autonomous systems. I build on emerging techniques in Multi-Agent Machine Learning and apply them to new training environments that are informed by real-world application scenarios involving drones.
                   {/*I design, develop and study drone systems for advancing collaborative aerial autonomy with a focus on time-critical operations. My research builds on emerging techniques in Machine Learning and Human-AI Collaboration in a quest for effective teaming between autonomous drones and experts in complex environments.*/}
                   <br/>
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container id="rjourney" maxWidth="lg" component="main" className={classes.mainPros} style={{fontFamily: 'Roboto Slab, sans-serif'}}>
            <Typography variant="h5" color="textPrimary" className={classes.textMain} style={{color:"#2c38c7"}}>
                In the news
            </Typography>
                <a href="https://www.projectorion.info/post/orion-official-launch-urban-air-mobility" rel="noopener" target="_blank" style={{textDecoration:"none"}} >
                    We are thrilled to announce the official launch of Project Orion!
                </a>
                <div style={{height: '15px'}}></div>

                <a href="https://news.cornell.edu/stories/2024/05/students-win-nasa-grant-develop-ai-safer-aerial-traffic" rel="noopener" target="_blank" style={{textDecoration:"none"}} >
                    Students win NASA grant to develop AI for safer aerial traffic
                </a>


                <br/>
                <div style={{height: '15px'}}></div>
            <a href="https://www.nasa.gov/aeronautics/university-teams-to-explore-aero-research/" rel="noopener" target="_blank" style={{textDecoration:"none"}} >
                NASA Selects University Teams to Explore Innovative Aeronautical Research
            </a>


                <br/>
                <div style={{height: '15px'}}></div>

                <a href="https://news.cornell.edu/stories/2023/05/commercialization-fellows-assess-innovations-potential" rel="noopener" target="_blank" style={{textDecoration:"none"}} >
                    Commercialization Fellows assess innovations’ potential
            </a>
                <br/>
                <div style={{height: '15px'}}></div>

                <a href="https://www.engineering.cornell.edu/2023-commercialization-fellows" rel="noopener" target="_blank" style={{textDecoration:"none"}} >
                    2023 Commercialization Fellows | Cornell Engineering
            </a>
                <br/>
                <div style={{height: '15px'}}></div>

                <a href="https://www.youtube.com/watch?v=SVXcOMRnPMQ" rel="noopener" target="_blank" style={{textDecoration:"none"}} >
                    Ph.D. student Mehrnaz Sabet advances autonomous drone systems with industry partners
                </a>
                <br/>
                <div style={{height: '15px'}}></div>

                <a href="https://cis.cornell.edu/graduate-student-spotlight-mehrnaz-sabet" rel="noopener" target="_blank" style={{textDecoration:"none"}} >
                    GRADUATE STUDENT SPOTLIGHT: MEHRNAZ SABET
                </a>
            </Container>
            <Container id="rjourney" maxWidth="lg" component="main" className={classes.mainPros}>
                <Typography variant="h3" color="textPrimary" className={classes.textMain} style={{color:"#2c38c7"}}>
                    Research Journey
                </Typography>
                <Typography variant="h6" color="textPrimary" className={classes.textMain}>
                    In many challenging real-world operation scenarios, robots need to
                    work alongside unpredictable human teammates to overcome complex goals. In my research, I argue that the complexity of these situations requires an autonomy that goes beyond the current scope of detection and navigation towards a collaborative autonomy that enables machines to coordinate with humans in different cooperative scenarios.
                </Typography>
                <Grid container spacing={3} alignItems="flex-end">

                    <Grid style={{position: 'relative'}} item xs={12}>
                        {/*<div className={classes.feature}>*/}

                        {/*</div>*/}
                        <Typography variant="h4" color="textPrimary" className={classes.textMain} style={{marginTop:"20px"}}>
                            Ground Zero - Beyond the walls of the lab
                        </Typography>
                        <Grid item xs={12} style={{display:"flex",background:"black", padding:"0"}} className={classes.featurex}>

                            <Grid item xs={12} md={8} style={{padding:"25px"}}>

                                <Typography variant="h6" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    To me, doing research is best when rooted in real-world problems
                                    </Typography>
                                    <br/>
                                <Typography variant="p" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    Choosing to work on any type of robot is a technical passion, mine is in flying robots that can one day affect many aspects of our life from saving people to getting foods delivered.
                                    <br/>
                                    <br/> To start doing research, I realized having expertise in what's not technically working is not enough. I wanted to identify challenges that integrating drones posed to real-world operation scenarios.
                                    <br/><br/>So I started looking into Search and Rescue missions. Search and Rescue (SAR) missions offer a unique perspective into different levels of complexities that can be involved in a collaborative operation scenario.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} style={{background:`url("${droneSAR}")`,backgroundSize:"cover"}}>
                                {/*<img src={droneSAR} style={{maxWidth:"90%"}} />*/}
                            </Grid>
                        </Grid>


                        <Typography variant="h4" color="textPrimary" className={classes.textMain}>
                            Ground One - Observe, Question, Study, Repeat!
                        </Typography>
                        <Grid item xs={12} style={{display:"flex",background:"black", padding:"0"}} className={classes.featurex}>

                            <Grid item xs={12} md={8} style={{padding:"25px"}}>

                                <Typography variant="h6" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    How can we identify the challenges by observing and studying?
                                </Typography>
                                <br/>
                                <Typography variant="p" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    Based on data collected from 150 missions and directly observing and interviewing more than 60 SAR operators,
                                    I created collaborative drone platforms and conducted field studies to better understand how human-drone teams work together to search challenging environments.
                                    <br/><br/>
                                    I learned that integrating drones in dynamic collaborative settings pose a challenge to coordination efficiency of human teams and disrupts their ability to rapidly coordinate different tasks.
                                    <br/><br/>
                                    So my goal became designing and testing new learning methods that'd enable drones/machines to learn to coordinate with humans as part of a team even under such complex scenarios.
                                   </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} style={{background:`url("${OBSRV}")`,backgroundSize:"cover",backgroundPosition:"center"}}>
                                {/*<img src={droneSAR} style={{maxWidth:"90%"}} />*/}
                            </Grid>
                        </Grid>
                        <Typography variant="h4" color="textPrimary" className={classes.textMain}>
                            Ground Two - Impossible? time to innovate!
                        </Typography>
                        <Grid item xs={12} style={{display:"flex",background:"black", padding:"0"}} className={classes.featurex}>

                            <Grid item xs={12} md={8} style={{padding:"25px"}}>

                                <Typography variant="h6" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    Being an engineer with a research mindset means to constantly innovate
                                </Typography>
                                <br/>
                                <Typography variant="p" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    To work on new multi-agent learning methods, I needed a way to do large-scale data collection due to limitation of field testing.
                                    <br/>
                                    <br/> I realized that there is a lack of performant multi-agent simulation testbeds for recreating the scenarios that I'd need.
                                    <br/><br/>
                                    So I implemented an advanced simulation platform for distributed multi-agent settings with human-in-the-loop features with performant data collection and training pipelines. </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} style={{background:`url("${Innov}")`,backgroundSize:"cover"}}>
                                {/*<img src={droneSAR} style={{maxWidth:"90%"}} />*/}
                            </Grid>
                        </Grid>
                        <Typography variant="h4" color="textPrimary" className={classes.textMain}>
                            Ground Four - Busy making a breakthrough!
                        </Typography>
                        <Grid item xs={12} style={{display:"flex",background:"black", padding:"0"}} className={classes.featurex}>

                            <Grid item xs={12} md={8} style={{padding:"25px"}}>

                                <Typography variant="h6" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    Now on to the new cooperative policies
                                </Typography>
                                <br/>
                                <Typography variant="p" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                            I am currently working on a new method for multi-agent learning built on top of foundations of multi-agent reinforcement learning
                                    <br/>
                                    <br/> The novelty of the learning method for multi-agent coordination is expected to result in better coordination amongst agents and also increase adaption to human teammates
                                    <br/><br/> An awarded NASA grant has enabled me to study the new model in a new type of setting in addition to the complex search scenarios and that is structured urban air mobility scenarios that'd need coordination between vehicles
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} style={{background:`url("${BT}")`,backgroundSize:"cover", backgroundPosition:"center"}}>
                                {/*<img src={droneSAR} style={{maxWidth:"90%"}} />*/}
                            </Grid>
                        </Grid>
                        <Typography variant="h4" color="textPrimary" className={classes.textMain}>
                            Roof - View from the top
                        </Typography>
                        <Grid item xs={12} style={{display:"flex",background:"black", padding:"0"}} className={classes.featurex}>

                            <Grid item xs={12} md={8} style={{padding:"25px"}}>

                                <Typography variant="h6" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    Keeping an eye for future impact
                                </Typography>
                                <br/>
                                <Typography variant="p" color="textPrimary" className={classes.textMain} style={{color:"White"}}>
                                    I believe the outcome of my research will enable machines to become better teammates especially for task scenarios where coordination is key.
                                    I'm hoping learning effective cooperative policies will result in better integration of autonomous machines and robots in a range of applications.
                                    <br/>
                                    <br/>
                                     </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} style={{background:`url("${Roof}")`,backgroundSize:"cover"}}>
                                {/*<img src={droneSAR} style={{maxWidth:"90%"}} />*/}
                            </Grid>
                        </Grid>


                        {/*<div className={classes.feature} style={{height:"100%", background:"white"}}></div>*/}
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" component="main" className={classes.mainPros} id="awards">
                <Typography variant="h3" color="textPrimary" className={classes.textMain} style={{color:"#2c38c7"}}>
                    Awards
                </Typography>
                <Grid container spacing={3} alignItems="flex-end">

                    <Grid style={{position: 'relative'}} item xs={12}>
                        <Typography variant="h6" color="textPrimary" className={classes.text} >
                            <span style={{marginRight:"20px"}}>2024</span>    <span style={{fontWeight: 'bold'}}> NASA University Student Research Challenge – Grant: $80k </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Awarded to student research projects with novel approaches to solving some of the biggest technical
                            challenges facing aviation as identified by NASA’s Aeronautics Research Mission Directorate
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2024</span>    <span style={{fontWeight: 'bold'}}> Global Advanced Air Mobility Academic Paper Competition Finalist </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Winner announcements in May’24
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2023</span>  <span style={{fontWeight: 'bold'}}> NSF Innovation Corps National Award – Grant: $50k </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Awarded to top researchers in science and engineering fields with promising lab inventions
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2023</span>  <span style={{fontWeight: 'bold'}}>
                            Cornell Engineering Commercialization Fellowship
                        </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Awarded to three PhD candidates with research-based impactful technology innovations
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2023</span>  <span style={{fontWeight: 'bold'}}>
                            NSF Spirit of I-Corps Award
                        </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Awarded for demonstrating excellence in leadership and execution during the national program
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2022</span>  <span style={{fontWeight: 'bold'}}>
                            ACM Best Paper Award
                        </span>
                        </Typography>

                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2020</span>  <span style={{fontWeight: 'bold'}}>
                            Best ECE Undergraduate Thesis Project for Fundamental Design and Innovation
                        </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Awarded by University of Tehran
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2020</span>  <span style={{fontWeight: 'bold'}}>
                            Best Computer Eng. Undergraduate Thesis Project
                        </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Awarded by University of Tehran
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginTop:"15px"}}>
                            <span style={{marginRight:"20px"}}>2020</span>  <span style={{fontWeight: 'bold'}}>
                            (4 x) Best Undergraduate Thesis Project by Industry
                        </span>
                        </Typography>
                        <Typography variant="h6" color="textPrimary" className={classes.text} style={{paddingLeft:"6%"}}>
                            Awarded by 4 different commercial companies
                        </Typography>


                    </Grid>
                </Grid>
            </Container>

                <Container id="academic" maxWidth="lg" component="main" className={classes.mainPros} >
                <Typography variant="h3" color="textPrimary" className={classes.textMain} style={{color:"#2c38c7"}}>
                    Research Experience
                </Typography>
                <div style={{height: '15px'}}></div>

                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                    Cornell University <span>— Lead Researcher on Human-Drone Systems</span>
                </Typography>
                <div style={{display: 'flex', margin: '10px 0'}}>
                    <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                        <span style={{fontWeight:'bold'}}> Collaborative Technologies Lab </span>
                    </Typography>
                </div>
                <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                    From  <span style={{fontWeight:'bold'}}> January 2021 </span> To  <span style={{fontWeight:'bold'}}> Present </span>
                </Typography>


                <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>

                    - <span style={{fontWeight:"600"}}>Currently working on implementing a new multi-agent machine learning algorithm for learning
cooperative policies from humans in partially observable multi-agent simulation environments</span><br />
                    <div style={{height: '15px'}}></div>

                    - Implemented a suite of multi-agent human-in-the-loop cooperative simulation training environments
                    informed by real-world search missions collected from more than 60 search and rescue drone operations<br />
                    <div style={{height: '15px'}}></div>

                    - Created an optimized training pipeline for on-policy MARL baseline finetuning in high-fidelity simulation
                    environments<br />

                    <div style={{height: '15px'}}></div>
                    - Conducted studies on a new system for human-machine teaming data collection using a multi-person
                    human-drone platform [paper submission underway] <br />
                </Typography>

                {/*<div style={{display: 'flex', margin: '10px 0px'}}>*/}
                {/*    <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>*/}
                {/*        <span style={{fontWeight:'bold'}}> Drone Perception & Autonomy </span>*/}
                {/*    </Typography>*/}
                {/*</div>*/}
                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold', marginTop: "30px"}}>
                    NASA University Student Research Challenge   <span>— Team Lead</span>
                </Typography>


                <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '10px 0 15px 0'}} className={classes.text}>
                    From  <span style={{fontWeight:'bold'}}> Feb 2024 </span> To  <span style={{fontWeight:'bold'}}> Present </span>
                </Typography>

                <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                    <Typography variant="body1" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                        <span style={{fontWeight:'bold'}}> TITLE: Learning cooperative policies for adaptive human-drone teaming in shared airspace </span>
                    </Typography>
                    <div style={{height: '15px'}}></div>

                    -	Building on my PhD research work to develop and test new cooperative policies for advanced urban air
                    mobility scenarios   <div style={{height: '15px'}}></div>
                    -	Implementing end-to-end data engine for training and testing multi-agent learning baselines in new
                    simulation configurations that represent challenges faced in urban air mobility scenarios <div style={{height: '15px'}}></div>
                    -	Leading a multi-disciplinary team of 7 undergraduate and master students across CS, IS, and ECE <div style={{height: '15px'}}></div>


                </Typography>

                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold', marginTop: "30px"}}>
                    Microsoft Autonomous Systems Group
                </Typography>

                <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '10px 0 15px 0'}} className={classes.text}>
                    From  <span style={{fontWeight:'bold'}}> June 2022 </span> To  <span style={{fontWeight:'bold'}}> August 2022 </span> — Intern
                </Typography>

                <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>


                    - Designed, developed and studied a new procedural generative aerial synthetic training data augmentation framework that increases end data variants by 75% and contributes to increased generalizability of trained models<br />
                    <div style={{height: '15px'}}></div>
                    - Designed and implemented an adaptive Domain Randomization approach for type-agnostic realistic scene augmentation to address sim-to-real
                    <div style={{height: '15px'}}></div>
                    - Proposed a new iterative data collection optimization approach for efficiently generating synthetic aerial datasets to meet a performance target
                    <div style={{height: '15px'}}></div>
                    - Ran experiments on representation learning methods for localization towards improving vision-based drone navigation using multi-modal synthetic data

                </Typography>
            </Container>
            <Container id="publications" maxWidth="lg" component="main" className={classes.mainPros}>
                <Grid container spacing={3} alignItems="flex-end">

                    <Grid style={{position: 'relative'}} item xs={12}>

                        <div className={classes.feature} style={{height:"100%", background:"white", marginBottom:"30px"}}>


                            <div id="exp" style={{height: '15px'}}></div>

                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold',marginBottom:"15px"}}>
                                    <span style={{fontWeight:'bold', color:"#2c38c7"}}>Publications </span>
                                </Typography>
                            </div>

                            <div className={classes.sectionDesktop}>

                            </div>

                            <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}} >
                                Intelligent Testbeds for Aerial Autonomy Assurance in Cooperative Airspace via Large Language Models
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> [under review] in Global AAM Academic Paper Competition for submission in the Drone Systems and Applications journal 2024  </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 0px 0'}} className={classes.text}>
                                <span style={{fontWeight:'bold'}}> Mehrnaz Sabet </span>
                            </Typography>
                            <div id="exp" style={{height: '15px'}}></div>

                            <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}} >
                                Scalable Modular Synthetic Data Generation for Advancing Aerial Autonomy
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Robotics and Autonomous Systems 166  </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 0px 0'}} className={classes.text}>
                                <span style={{fontWeight:'bold'}}> Mehrnaz Sabet, </span> Praveen Palanisamy, Sakshi Mishra
                            </Typography>
                            <div className={classes.ButtonsMore} style={{ margin: '-10px 0 15px 0'}}>
                                <Button variant="contained" className={classes.MarginButton} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} target="_blank" href="https://doi.org/10.1016/j.robot.2023.104464">
                                    Link to paper
                                </Button>
                                <Button variant="contained" className={classes.MarginButton} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} target="_blank" href="http://arxiv.org/abs/2211.05335">
                                    Link to arXiv
                                </Button>
                                <Button className={classes.MarginButtonBottt} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} variant="contained" color="primary" href="https://www.youtube.com/watch?v=eKpOh-K-NfQ" target="_blank">
                                    Watch the video
                                </Button>
                            </div>
                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                - This paper addresses generalizability and scalability challenges in aerial synthetic data generation for sim-to-real by:
                                <br />
                                <div style={{height: '15px'}}></div>
                                - Introducing ASDA, procedural generative aerial synthetic data augmentation framework for generating diverse aerial synthetic datasets.
                                <br />
                                <div style={{height: '15px'}}></div>
                                - Adaptive scene augmentation through a multi-layer domain randomization approach guided by scene graph information.
                                <br />
                                <div style={{height: '15px'}}></div>
                                - Increasing aerial synthetic data generation efficiency by enabling flexible control through a unified prompt-based interface.
                                <br />
                                <div style={{height: '15px'}}></div>
                                - Our approach results in 75% more variants in the end data and increases data generation efficiency by 50%.
                                <br />
                                <div style={{height: '15px'}}></div>
                                - Iterative data collection optimization method based on ASDA’s workflow to meet a performance target.
                                <br />
                                <div style={{height: '15px'}}></div>

                            </Typography>


                            <div style={{height: '25px'}}></div>

                            <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                Designing Telepresence Drones to Support Synchronous, Mid-air Remote Collaboration: An Exploratory Study
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> ACM Conference on Human Factors in Computing Systems - CHI 2021 </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 0px 0'}} className={classes.text}>
                                  <span style={{fontWeight:'bold'}}> Mehrnaz Sabet, </span> Mania Orand,  David W. McDonald
                            </Typography>
                            <div className={classes.ButtonsMore} style={{ margin: '-10px 0 15px 0'}}>
                                <Button variant="contained" className={classes.MarginButton} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} target="_blank" href="https://dl.acm.org/doi/10.1145/3411764.3445041">
                                    Link to paper
                                </Button>
                                <Button className={classes.MarginButtonBottt} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} variant="contained" color="primary" href="https://www.youtube.com/watch?v=hfRLq3JaeBI" target="_blank">
                                    Watch the video
                                </Button>
                            </div>
                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                - This paper describes the design and development of a novel
                                telepresence platform using drones that supports collaboration and mid-air interaction among multiple remote and onsite users through three design iterations and evaluations <br />

                            </Typography>



                            {/*<div style={{height: '25px'}}></div>*/}




                            <div className={classes.sectionDesktop}>

                            </div>
                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold',marginTop:"20px"}}>
                                    <span style={{fontWeight:'bold', color:"#2c38c7"}}>Projects </span>
                                </Typography>
                            </div>

                            <div style={{height: '15px'}}></div>
                            <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                Advanced simulation system for validating autonomous robots
                            </Typography>

                            <Typography variant="overline" display="block" style={{ margin: '10px 0 15px 0'}} className={classes.text}>
                                From  <span style={{fontWeight:'bold'}}>Feb 2021 </span> to  <span style={{fontWeight:'bold'}}>May 2023</span>
                            </Typography>
                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                - Implemented an advanced modular simulation system to validate the autonomy behaviors in autonomous robots. This system provides scalable testbeds for robot autonomy through a modular data-driven environment
                                <br/><div style={{height: '15px'}}></div>
                                - Implemented a novel approach in multi-modal data collection, through a performant rendering mechanism that
                                enables the simulator to generate large-scale test data to validate autonomy behaviors for single or multi-robot
                                scenarios thus mitigating risk of deployment and accelerating test and validation
                                <br/><div style={{height: '15px'}}></div>
                                - <span style={{fontWeight:'bold'}}> This system has won 2 major awards including an NSF grant and is actively being used for research </span>
                            </Typography>
                            <div style={{height: '15px'}}></div>
                            <div style={{height: '15px'}}></div>
                            <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                Integrating quadcopter drones to ad-hoc operations during disaster response
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> B.Sc. Thesis – Department of Electrical and Computer Engineering </span>
                                </Typography>
                            </div>


                            <Typography variant="overline" display="block" style={{ margin: '10px 0 15px 0'}} className={classes.text}>
                                From  <span style={{fontWeight:'bold'}}>January 2018 </span> to  <span style={{fontWeight:'bold'}}>February 2020</span>
                            </Typography>
                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`} >
                                -	Studied first responders’ collaboration during natural disasters and designed a platform prototype using quadcopter drones, advanced cloud computing, machine learning and data visualization techniques to support and facilitate drone operations in distributed teams for effective response.<br/>
                                <br/>
                                - <span style={{fontWeight:'bold'}}> This project received a total of 6 awards from academia and industry </span>
                            </Typography>



                              </div>
                    </Grid>


                </Grid>
            </Container>

            <Container id="pact" maxWidth="lg" component="main" className={classes.mainPros}>
                <Typography variant="h3" color="textPrimary" className={classes.textMain} style={{color:"#2c38c7"}}>
                    Professional Activities
                </Typography>
                <div style={{height: '15px'}}></div>
                <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                    Project Director, Shaping Autonomy
                </Typography>
                <Typography variant="overline" display="block" style={{ margin: '10px 0 15px 0'}} className={classes.text}>
                    From  <span style={{fontWeight:'bold'}}>August 2023 </span> to  <span style={{fontWeight:'bold'}}>Present</span>
                </Typography>
                <Button variant="contained" className={classes.MarginButton} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} target="_blank" href="https://shapingautonomy.com">
                    Go to Website
                </Button>
                <div style={{height: '25px'}}></div>

                <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                    -                     Initiated this project with the mission to bridge the gap between industry and academia for advancing the field of robotics and autonomous systems
                    <br/>
                    <div style={{height: '15px'}}></div>
                    - Conducted more than 100 interviews with industry experts for inspiring applied cross-institutional projects, and empowering research and engineering community
                    <br/>
                    <div style={{height: '15px'}}></div>
                    - Currently working with industry researchers on publishing the findings on key challenges
                    <br/>
                    <div style={{height: '15px'}}></div>
                    - Initiative backed by NSF and supported by three major partnerships with leading industry associations
                    <br/>
                 <div style={{height: '15px'}}></div>
                </Typography>
                <div style={{height: '15px'}}></div>
                <div style={{height: '15px'}}></div>

                <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                    Technical Committee Member, Human-Machine Teaming, AIAA
                </Typography>
                <div style={{height: '15px'}}></div>

                <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                    Friend of the Technical Committee, Intelligent Systems, AIAA
                </Typography>

                <div style={{height: '15px'}}></div>

                <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                    Multi-Vehicle Control (m:N) Working Group member, sub-group lead, NASA
                </Typography>
                <div style={{height: '15px'}}></div>

                <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                    Associate member, Association for Uncrewed Vehicle Systems International (AUVSI)
                </Typography>
                <div style={{height: '15px'}}></div>
                <Typography variant="h6" color="textPrimary" className={classes.text}>
                    <span style={{fontWeight:'bold'}}> Speaker, </span> AUVSI '24, Shaping Autonomy: Enabling New Generation of Collaborations Towards Desired
                    Outcomes
                </Typography>
                <div style={{height: '15px'}}></div>
                <Typography variant="h6" color="textPrimary" className={classes.text}>
                    <span style={{fontWeight:'bold'}}> Session Chair, </span> AUVSI '23, Designing Autonomy: Data Foundations
                </Typography>
                <div style={{height: '15px'}}></div>
                <Typography variant="h6" color="textPrimary" className={classes.text}>
                    <span style={{fontWeight:'bold'}}> Speaker, </span>  Women & Drones Coffee Connection, Advancing the field through new collaborations, Oct 2023
                </Typography>
                <div style={{height: '15px'}}></div>
                <Typography variant="h6" color="textPrimary" className={classes.text}>
                    <span style={{fontWeight:'bold'}}> Reviewer, </span>  IEEE Transactions on Circuits and Systems II: Express Briefs, ACM Human Factors in Computing Systems (CHI), International Journal of Drones, ACM CSCW</Typography>

            </Container>

            <Container maxWidth="lg" component="main" className={classes.mainPros}>
                <Typography variant="h3" color="textPrimary" className={classes.textMain}>
                    Other Experiences
                </Typography>
                <Grid container spacing={3} alignItems="flex-end">

                    <Grid style={{position: 'relative'}} item xs={12}>
                        {/*<div className={classes.feature}>*/}

                        {/*</div>*/}
                        <div className={classes.featurez} style={{height:"100%", background:"#d9d9d9"}}>
                            <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                Cornell Tech <span>— Graduate Research Assistant</span>
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Computer Security and Privacy Lab </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                                From  <span style={{fontWeight:'bold'}}> June 2021 </span> To  <span style={{fontWeight:'bold'}}> August 2021 </span>
                            </Typography>
                            {/*<div style={{display: 'flex', margin: '10px 0'}}>*/}
                            {/*    <Typography variant="body1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>*/}
                            {/*        <span style={{fontWeight:'bold',color:"red"}}> Current Projects: </span>*/}
                            {/*    </Typography>*/}
                            {/*</div>*/}
                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Emerging IoT Technologies </span>
                                </Typography>
                            </div>

                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                -	Studied the role of emerging IoT devices (e.g. consumer drones, smart home, etc.) in computer security for survivors of IPV <br />
                                <div style={{height: '15px'}}></div>
                                -	Led design sessions for building detection and expert intervention tools in IoT-enabled IPV<br />
                            </Typography>


                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Clinical Computer Security </span>
                                </Typography>
                            </div>

                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                - Conducted multiple rounds of interviews with case workers and security experts <br />
                                <div style={{height: '15px'}}></div>
                                - Ran multiple rounds of analysis over unstructured data to develop codebooks <br />
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold',marginTop:"20px"}}>
                                    <span style={{fontWeight:'bold'}}>Publications </span>
                                </Typography>
                            </div>
                            <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                Care Infrastructures for Digital Privacy and Security in Intimate Partner Violence
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> ACM Conference on Human Factors in Computing Systems - CHI 2022 </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                                Emily Tseng, <span style={{fontWeight:'bold'}}> Mehrnaz Sabet </span>, Rosanna Bellini, Hakiran Sodhi, Thomas Ristenpart, and Nicola Dell
                            </Typography>

                            <div className={classes.ButtonsMore}>
                                <Button variant="contained" className={classes.MarginButton} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} target="_blank" href="https://nixdell.com/papers/Tseng-2022-CHI-Care-Infrastructures-Digital-Privacy-IPV.pdf">
                                    Learn More
                                </Button>
                            </div>

                        </div>
                    </Grid>
                    <Grid style={{position: 'relative'}} item xs={12}>
                        {/*<div className={classes.feature}>*/}

                        {/*</div>*/}
                        <div className={classes.featurez} style={{height:"100%", background:"#d9d9d9"}}>
                            <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                VR Embodiment Lab <span style={{fontSize:"18px"}}>— Graduate Research Assistant</span>
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Cornell University </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                                From  <span style={{fontWeight:'bold'}}> September 2021 </span> To  <span style={{fontWeight:'bold'}}> December 2021 </span>
                            </Typography>

                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Social Virtual Reality </span>
                                </Typography>
                            </div>

                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                -	Co-designed a research study to understand effects of social virtual reality on perception of pain <br />
                                <div style={{height: '15px'}}></div>
                                - Ran lab experiments as the lead researcher with more than 80 recruited participants for the study <br />
                                <div style={{height: '15px'}}></div>
                                - Conducted qualitative analysis over FPV recordings in VR to compare effects of environmental distractions to avatar representations on social communications <br />
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold',marginTop:"20px"}}>
                                    <span style={{fontWeight:'bold'}}>Publications </span>
                                </Typography>
                            </div>
                            <Typography variant="h6" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                Effects of Mediated Social Interactions on Pain
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> [ACCEPTED] International Communication Association Conference - ICA 2022 </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                                Andrea Won, <span style={{fontWeight:'bold'}}> Mehrnaz Sabet </span>, Angel Hsing-Chi Hwang, Julie Cannon, Vera Gaddi, and S. McLeod-Daphnis
                            </Typography>

                        </div>
                    </Grid>
                    <Grid style={{position: 'relative'}} item xs={12}>
                        {/*<div className={classes.feature}>*/}

                        {/*</div>*/}
                        <div className={classes.featurez} style={{height:"100%", background:"#d9d9d9"}}>
                            <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                Ambient Intelligence Lab <span style={{fontSize:"18px"}}>— Undergraduate Research Assistant</span>
                            </Typography>
                            <div style={{display: 'flex', margin: '10px 0'}}>
                                <Typography variant="subtitle1" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Sharif University of Technology </span>
                                </Typography>
                            </div>
                            <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                                From  <span style={{fontWeight:'bold'}}> April 2018 </span> To  <span style={{fontWeight:'bold'}}> October 2018 </span>
                            </Typography>

                            <div style={{display: 'flex', margin: '10px 0px'}}>
                                <Typography variant="h6" color="textPrimary" className={classes.text} style={{marginRight: '5%'}}>
                                    <span style={{fontWeight:'bold'}}> Smart Office </span>
                                </Typography>
                            </div>

                            <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>

                                - Studied the usability and efficiency of different landing page generation tools for smart office<br />
                                <div style={{height: '15px'}}></div>
                                - Developed a landing page generating tool customized for communicating advanced IoT concepts to
                                users while keeping conversion rate high<br />
                                <div style={{height: '15px'}}></div>
                                - Studied landing page optimization methods and proposed a method based on the combination of
                                weighted bandit algorithm and genetic programming for real-time multivariate optimization<br />

                            </Typography>

                        </div>
                    </Grid>
                    <Grid container spacing={3} alignItems="flex-end">
                        <Grid style={{position: 'relative'}} item xs={12} sm={12}>
                            <div className={classes.featurez} style={{background:"#d9d9d9"}}>

                                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                    Lead Front-end Developer
                                </Typography>
                                <div style={{display: 'flex', margin: '10px 0'}}>
                                    <Typography variant="subtitle1" color="textPrimary" className={classes.text}>
                                        <span style={{fontWeight:'bold'}}> Offerland </span> - next-generation analytic tool for residential real estate based in Canada
                                    </Typography>
                                </div>
                                <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                                    From  <span style={{fontWeight:'bold'}}> March 2020 </span>  To  <span style={{fontWeight:'bold'}}> January 2021 </span>
                                </Typography>
                                <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                    -	Developed a web app for managing over 1 million properties over an interactive map using optimized clustering algorithms
                                    <div style={{height: '15px'}}></div>
                                    -	Developed the main web application offering services including property price estimation and offer value accuracy in React<br />
                                    <div style={{height: '15px'}}></div>
                                    -	Designed UI/UX for the main website and its services<br />
                                </Typography>
                                <div className={classes.ButtonsMore2}>
                                    <Button variant="contained" className={classes.MarginButtonBott} style={{backgroundColor:'black',color:'white',padding:'10px 15px'}} target="_blank" href="https://offerland.ca">
                                        Learn More
                                    </Button>
                                </div>
                                {/*<img src={HomeValue} className={classes.tapc} />*/}
                                <div className={classes.sectionDesktop}>
                                    {/*<Lottie options={defaultOptions("realState")}*/}
                                    {/*        style={{position:"absolute", right:"0", top: "0px"}}*/}
                                    {/*    // height={250}*/}
                                    {/*        width={"40%"}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="flex-end">
                        <Grid style={{position: 'relative'}} item xs={12} sm={12}>
                            <div className={classes.featurez} style={{background:"#d9d9d9"}}>

                                <Typography variant="h5" color="textPrimary" className={classes.text} style={{fontWeight: 'bold'}}>
                                    Lead Front-end Developer
                                </Typography>
                                <div style={{display: 'flex', margin: '10px 0'}}>
                                    <Typography variant="subtitle1" color="textPrimary" className={classes.text}>
                                        <span style={{fontWeight:'bold'}}> TAPSI </span> - Ridesharing Company
                                    </Typography>
                                </div>
                                <Typography variant="overline" display="block" color="textPrimary" style={{ margin: '-10px 0 15px 0'}} className={classes.text}>
                                    From  <span style={{fontWeight:'bold'}}> November 2016 </span>  To  <span style={{fontWeight:'bold'}}> February 2020 </span>
                                </Typography>
                                <Typography variant="body1" color="textPrimary"  className={`${classes.text} ${classes.DEsc}`}>
                                    -	Led a team of 5 software developers as Automation/Web team<br />
                                    <div style={{height: '15px'}}></div>
                                    -	Designed and developed 8 fundamental web applications based on company’s services<br />
                                    <div style={{height: '15px'}}></div>
                                    -	Conducted UX research for various CRM web application prototypes and developed the web based on results<br />
                                    <div style={{height: '15px'}}></div>
                                    -	Improved the customer service performance cutting the phone calls time by 50% with the CRM web application<br />
                                    <div style={{height: '15px'}}></div>
                                    -	Developed passenger progressive web application in collaboration with my team that handled 15% of the rides
                                </Typography>

                                <div className={classes.sectionDesktop}>

                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>



            {/* Footer */}
            <Container maxWidth="lg" component="footer" className={classes.footer}>

                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
            {/* End footer */}
        </React.Fragment>
    );
}
